import Base from './Base';

export default class Auth extends Base {
  login = async data => {
    return this.sendRequest({
      path: `/api/v1/useraccount/login`,
      method: 'POST',
      data,
    });
  };

  profile = async id => {
    return this.sendRequest({
      path: `/api/v1/user/${id}`,
    });
  };

  theme = async () => {
    return this.sendRequest({
      path: `/api/School/Theme`,
    });
  };
}
