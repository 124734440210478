import React from 'react'
import { useState } from 'react'
import { Form, Modal, Button } from 'react-bootstrap'
// import CourseAPI from '../../../api/Course'
import { toast } from 'react-toastify'
import Course from '../../../api/Course'
import Loader from '../../../components/Loader'
import { BASE_URL } from '../../../constant/url'

function CreateCoursesModal({ showCreateModal, setShowCreateModal, getUserCourse, getAllCourse, handleSelectedFile, coverPathBase, setShowLogin }) {
  const [courseName, setCourseName] = useState('')
  const [courseDescription, setCourseDescription] = useState('')
  const [link, setLink] = useState('')
  const [price, setPrice] = useState('')
  // const [coverPhoto, setCoverPhoto] = useState('')
  const [pathBase, setPathBase] = useState('')
  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);


  const createCourse = async (e) => {
    e.preventDefault()
    console.log({e})
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    if (courseName === '' || courseDescription === '') {
      toast.error('Please input all required fields', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoader(true)
      const formData = {
        "course_name": courseName,
        "course_description": courseDescription,
        "course_cover": pathBase,
        "subject_area_id": 1,
        "link": link,
        "price": price
      }
      const token = await window.localStorage.getItem("token")
      console.log({formData, token})

      const response = await new Course().createCourse(formData)
      console.log({response})
      if (response?.ok) {
        setShowCreateModal(false)
        alert('Successfully Created Course')
        getAllCourse()
        handleCloseModel()
      } else {
        if(response?.data?.message === "Unauthorized!"){
          setShowLogin(true)
          setShowCreateModal(false)
        }else{
          alert(response?.data?.message)
        }
      }
      setTimeout(() => {
        setLoader(false)
      }, 3000);
    }
  }

  const handleCloseModel = () => {
    setShowCreateModal(false)
    setValidated(false)
    setCourseName('')
    setCourseDescription('')
    setPathBase('')
  }

  const handleFileCover = (file) => {
    // file.persist();
    // let text = (file?.target?.value).toString()
    setPathBase(file)
    // await window.localStorage.setItem("path", text)
    // console.log({pathBase: file.target.value, file})
  }

  return (
    <Modal
      size="lg"
      show={showCreateModal}
      onHide={() => handleCloseModel()}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Create Book
        </Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={(e) => createCourse(e)} >
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Book Name:</Form.Label>
            <Form.Control required onChange={(e) => setCourseName(e.target.value)} type="text" placeholder="Type Book Name here" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Book Description:</Form.Label>
            <Form.Control required onChange={(e) => setCourseDescription(e.target.value)} type="text" placeholder="Type Book Description here" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Link:</Form.Label>
            <Form.Control required onChange={(e) => setLink(e.target.value)} type="text" placeholder="Type Book Link here" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Price:</Form.Label>
            <Form.Control required onChange={(e) => setPrice(e.target.value)} type="text" placeholder="Type Book Price here" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Book Cover: <i>Require size 320x120</i></Form.Label>
            <Form.Control type='file' accept='.png, .jpg, .jpeg' onChange={(e) => handleFileCover(e.target.files[0])}></Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={() => setShowCreateModal(!showCreateModal)} variant="secondary">
            Close
          </Button> */}
          <Button type='submit' variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Form>
      {loader && <Loader />}
    </Modal>
  )
}

export default CreateCoursesModal