import React from 'react'
import { useState, useContext } from 'react'
import { Row, Col, Card, Form, InputGroup } from 'react-bootstrap'
import icon from '../../../assets/images/Marketing.png'
import close from '../../../assets/images/close.png'


function Book({ book, baseUrl, setShowBook }) {
  
  return (
    <div className='course-header'>
    <Row style={{justifyContent: 'right'}}>
        <Card.Img style={{height: 30, width: 50}} src={close} onClick={() => setShowBook(false)} />
    </Row>
      <Row>
        <Col className='resources-header'>
            <Card.Img className='card-img' src={book?.course_cover === null ? `${icon}` : `${baseUrl}${book?.course_cover}`} />
        </Col>
        <Col className='resources-header'>
          <b><h1>{book?.course_name}</h1></b>
          <hr />
          <h3>{'Description'}</h3>
          <p>{book?.course_description}</p>
          <h5>{'Link'}</h5>
          <p>{book?.link}</p>
          <h5>{'Price'}</h5>
          <p>{book?.price}</p>
        </Col>
      </Row>
    </div>

  )
}

export default Book