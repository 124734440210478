import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

// import { UserContext } from "../context/UserContext";
import Dashboard from "./../views/Dashboard/Dashboard"

export default function Routes() {
  // const userContext = useContext(UserContext);
  // const {loading, refreshUser} = userContext.data

  // useEffect(() => {
  //   refreshUser()
  // }, [])

  return (
    <div className="content">
      <Router>
          <Switch>
            <Route path='/' exact component={Dashboard}/>
          </Switch>
        </Router>
    </div>
  );
}
