import React from 'react'
import { useState, useContext } from 'react'
// import { UserContext } from '../../../context/UserContext'
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap'
import search from '../../../assets/images/icons/Icon ionic-ios-search-blue.png'
import logo from '../../../assets/images/tfiLogo2.png'
import { UserContext } from '../../../context/UserContext'
import Login from '../../Login/Login'
import CreateCoursesModal from './CreateCoursesModal'



function AddCourseHeader({ getUserCourse, onSearch, getAllCourse, handleSelectedFile, coverPathBase }) {
  const userContext = useContext(UserContext)
  const { user } = userContext.data
  const isAdmin = localStorage.getItem('role') === 'Admin' ? true : false
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });


  const HandleShowModal = () => {
    if(user != null || user != false){
      setShowCreateModal(true)
    }else{
      setShowLogin(true)
    }
  }
  console.log({user})
  return (
    <div className='course-header'>
      <Row>
        <Col className='resources-header'>
          <a href='https://techfactors.com/'>
            <img style={{height: 100}} src={logo} className='icon' alt='logo' />
          </a>
        </Col>
      </Row>
      <Row className='search-box' >
        <Col md="auto" className='font-size' >
          <span className='d-flex'>
            <b><p>Book List</p></b> &nbsp; &nbsp;
            {/* <span><Button onClick={() => HandleShowModal()} size="sm" className='btn-add-course' > <i className="fa fa-plus plus"></i>Add Book</Button></span> */}
          </span>
        </Col>
        <Col className='search' >
          <InputGroup className="mb-3 searchbar">
            <InputGroup.Text className='search-icon' id="basic-addon1"><img src={search} className='icon' /></InputGroup.Text>
            <Form.Control
              className='searchtab'
              placeholder="Search here for available book"
              aria-label="Search"
              aria-describedby="basic-addon1"
              onChange={(e) => onSearch(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <CreateCoursesModal
        coverPathBase={coverPathBase}
        handleSelectedFile={handleSelectedFile}
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
        getUserCourse={getUserCourse}
        getAllCourse={getAllCourse}
        setShowLogin={setShowLogin}
      />
      <Login showLogin={showLogin} setShowLogin={setShowLogin} />
    </div>

  )
}

export default AddCourseHeader