import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, DropdownMenu, Card, Dropdown, Button, Modal, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import CourseAPI from '../../../api/Course'
import icon from '../../../assets/images/Marketing.png'
import SweetAlert from 'react-bootstrap-sweetalert'
import { toast } from 'react-toastify'
import UpdateCourseModal from '../modal/UpdateCourseModal'
import AddCourseHeader from './AddCourseHeader'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import icon3 from '../../../assets/images/warning.png';
import { BASE_URL } from '../../../constant/url'
import { UserContext } from '../../../context/UserContext'
import Book from './Book'



function CourseCard() {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const { user } = userContext.data
  const [courseList, setCourseList] = useState(null)
  const [showAlert, setShowAlert] = useState(false)
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const [courseId, setCourseId] = useState()
  const [subjectId, setSubjectId] = useState()
  const [showUpdateCourseModal, setShowUpdateCourseModal] = useState(false)
  const [courseName, setCourseName] = useState('')
  const [courseDescription, setCourseDescription] = useState('')
  const [link, setLink] = useState('')
  const [price, setPrice] = useState('')
  const [searchTeacherItem, setSearchTeacherItem] = useState('')
  const [courseColor, setCourseColor] = useState('')
  const [coverPathBase, setCoverPathBase] = useState('')
  const [showUploadCover, setShowUploadCover] = useState(false)
  const [ifFileSelected, setIfFileSelected] = useState(false)
  const [validated, setValidated] = useState (false)
  const [showBook, setShowBook] = useState(false)
  const [book, setBook] = useState(null)

  let baseUrl = `https://product.tekteachlms.com/`

  const getAllCourse = async () => {
    let response = await new CourseAPI().getAllCourse()
    if (response.ok) {
      setCourseList(response?.data?.Courses)
    } else {
      console.log(response.data)
    }
  }


  const updateCourseCover = async (e) => {
    e.preventDefault()
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    if (coverPathBase === '') {
      toast.error('Please input all required fields', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const formData = new FormData();
      formData.append('course_cover', coverPathBase);
      console.log({coverPathBase})

      const response = await fetch(`${BASE_URL}/api/v1/course/${courseId}/uploadCourseCover`, {
        method: 'PUT',
        headers: {
          "Authorization": `Bearer ${await window.localStorage.getItem("token")}`
        },
        body: formData
      });

      if (response.ok) {
        toast.success('Successfully uploaded cover photo', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setValidated(false);
        setShowUploadCover(false)
        setIfFileSelected(false)
        getAllCourse()
      } else {
        toast.warn('Uploading cover photo failed!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  const renderTooltipEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      More Actions
    </Tooltip>
  );

  const renderTooltip = props => (
    <Tooltip {...props}>{props.courseDescription}</Tooltip>
  );

  const deleteCourse = async (courseId) => {
    const response = await new CourseAPI().deleteCourse(courseId)
    if (response.ok) {
      toast.success('Successfully deleted course!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setShowAlertDelete(false)
      getAllCourse()
    // getUserCourse()
    } else {
      toast.warn('Something went wrong!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const handleDelete = (id) => {
    setShowAlertDelete(true)
    setCourseId(id)
  }

  const handleCancelDelete = () => {
    setShowAlertDelete(false)
  }

  const handleUpdate = (id, subject_area_id) => {
    setCourseId(id)
    setShowUpdateCourseModal(true)
    setSubjectId(subject_area_id)
  }

  const handleChangeCover = (item) => {
    setShowUploadCover(true)
    setCourseId(item)
    console.log('ID', item)
  }

  const handleViewBook = (item) => {
    setCourseId(item)
    setShowBook(true)
    setBook(item)
    console.log('ID', item)
  }

  const handleSelectedFile = (file) => {
    setIfFileSelected(true);
    setCoverPathBase(file)
  }

  useEffect(() => {
    getAllCourse()
  }, [])

  const onSearch = (text) => {
    setSearchTeacherItem(text)
  }

  const HandleViewProduct = item => {
    if(user != null || user !== false){
      
    }else{
      setShowBook(true)
      setBook(item)
    }
  }

  return (
    <>
      {showBook ? 
      <Book book={book} baseUrl={baseUrl} setShowBook={setShowBook} />
      :
      <>
      <AddCourseHeader coverPathBase={coverPathBase} handleSelectedFile={handleSelectedFile} getAllCourse={getAllCourse} onSearch={onSearch} />
      <Row className='course-tab'>
        <SweetAlert
          title={
            <>
              <img src={icon3} className='icon3' />
              <div>Are you sure you want to delete this course?</div>
            </>
          }
          onConfirm={() => deleteCourse(courseId)}
          onCancel={handleCancelDelete}
          confirmBtnText="Yes"
          cancelBtnText="No"
          btnSize="sm"
          show={showAlertDelete}
          showCancel={true}
          closeOnClickOutside={false}
          buttonsStyling={false}
          customButtons={
            <>
              <button
                onClick={handleCancelDelete}
                className='sweet-alert-btn1'
              >
                No
              </button>
              <button
                onClick={() => deleteCourse(courseId)}
                className='sweet-alert-btn2'
              >
                Yes
              </button>
            </>
          }
        />
        <Col className='col-margin-bottom' style={{justifyContent: 'center'}}>
          {courseList?.filter((item) => {
            if (searchTeacherItem == '') {
              return item
            } else if (item?.course_name.toLowerCase().includes(searchTeacherItem.toLocaleLowerCase())) {
              return item
            }
          }).sort((a , b) => a?.updated_at - b?.updated_at).map((item, index) => {
            return (
                <Card key={index} onClick={() => HandleViewProduct(item)} className='card' style={{'borderRadius':5}}>
                  <Card.Body>
                    <Row>
                      <Col className='text-truncate'>
                        <Card.Title className='course-card-title' >
                          {/* <OverlayTrigger placement="bottom" overlay={renderTooltip({ courseDescription: item.course_name })}> */}
                            <span style={{ color: '#000000' }} onClick={() => handleViewBook(item)}>{item?.course_name}</span>
                          {/* </OverlayTrigger> */}
                        </Card.Title>
                      </Col>
                      {/* {(user != null || user != false) && <Col sm={2} md={2} lg={2} xl={2} className='btn-holder-elipsis'>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 10, hide: 25 }}
                          overlay={renderTooltipEdit}>
                          <Dropdown className='dropdown-btn'>
                            <Dropdown.Toggle data-toggle="dropdown">
                              <i className="fa fa-ellipsis-v fa-1x"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleViewBook(item)}>View Book</Dropdown.Item>
                              <Dropdown.Item onClick={() => handleUpdate(item?.id, item?.subject_area_id)}>Edit</Dropdown.Item>
                              <Dropdown.Item onClick={() => handleDelete(item?.id)}>Delete</Dropdown.Item>
                              <Dropdown.Item onClick={() => handleChangeCover(item?.id)}>Change Cover</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </OverlayTrigger>
                      </Col>} */}
                    </Row>
                    <Card.Text className='course-card-text text-truncate' >
                      {/* <OverlayTrigger placement="bottom" overlay={renderTooltip({ courseDescription: item.course_description })}> */}
                        <span><b className='sub-text'>{item?.course_description}</b></span>
                      {/* </OverlayTrigger> */}
                    </Card.Text>
                    <Card.Img className='card-img' src={item?.course_cover === null ? `${icon}` : `${baseUrl}${item?.course_cover}`} />
                  </Card.Body>
                </Card>
              )
          })
          }
        </Col>
        <Modal
          size="lg"
          show={showUploadCover}
          onHide={() => setShowUploadCover(!showUploadCover)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header noValidate closeButton>
            <Modal.Title>
              Upload Cover Photo
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={updateCourseCover}>
            <Modal.Body>
              <Form.Control type='file' accept='.png, .jpg, .jpeg' onChange={(e) => handleSelectedFile(e.target.files[0])}></Form.Control>
            </Modal.Body>
            <Modal.Footer>
              <Button type='submit' variant="primary" disabled={!ifFileSelected} >
                Upload
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <UpdateCourseModal
          showUpdateCourseModal={showUpdateCourseModal}
          setShowUpdateCourseModal={setShowUpdateCourseModal}
          courseId={courseId}
          courseName={courseName}
          courseDescription={courseDescription}
          link={link}
          setLink={setLink}
          price={price}
          setPrice={setPrice}
          subjectId={subjectId}
          setCourseDescription={setCourseDescription}
          setCourseName={setCourseName}
          courseColor={courseColor}
          setCourseColor={setCourseColor}
          getAllCourse={getAllCourse}
        />
      </Row>
      </>  
    }
    
    </>
  )
}

export default CourseCard