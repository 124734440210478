import React, { useState, useContext } from 'react'
import { Form, Modal, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Auth from '../../api/Auth';
import { UserContext } from '../../context/UserContext';


export default function Login({ showLogin, setShowLogin }) {

  const userContext = useContext(UserContext);
  const {loading, refreshUser, user} = userContext.data
  const { register, handleSubmit, formState: { errors }  } = useForm({
    defaultValues: {
      username: '',
      password: ''
    }
  });

  const HandleLogin = async(value) => {
    const data = {
      "username": value?.username,
      "password": value?.password
    }
    const response = await new Auth().login(data)
    if(response.ok){
      console.log('res:', response.data)
      localStorage.setItem("token", response?.data?.token)
      localStorage.setItem("id", response?.data?.User?.user_id)
      console.log({user})
      refreshUser()
      setShowLogin(false)
      // // history.push('/dashboard')
      // if (response?.data?.UserAccount?.role_id === 3){
      //   if(response?.data?.UserAccount?.role_id === 3 && response?.data?.UserAccount?.assessment_status === true){
      //     history.push('/dashboard')
      //   }else{
      //     history.push('/assessmentPage1')
      //   }
      // } else {
      //   history.push('/course')
      // }
    }else{
      alert('Invalid credentials')
    }
  }

  const handleCloseModel = () => {
    setShowLogin(false)
  }

  return (
    <Modal
      size="lg"
      show={showLogin}
      onHide={() => handleCloseModel()}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Login
        </Modal.Title>
      </Modal.Header>
        <Form style={{padding: 40}} className='form' onSubmit={handleSubmit(HandleLogin)}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="custom-label">E-mail</Form.Label>
            <Form.Control {...register("username", { required: true })} className={`${errors.username ? 'hasError' : ''} form-control mb-0`} type="username" placeholder="Enter e-mail here..." />
          </Form.Group>
          <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
            <Form.Label className="custom-label">Password</Form.Label>
            <Form.Control {...register("password", { required: true })} className={`${errors.password ? 'hasError' : ''} padding-right-72px`} type={'password'} placeholder="Enter password here..." />
            {/* <i className={showPasswordText ? 'fa fa-eye eye-position' : 'fa fa-eye-slash eye-position'} onClick={() => setShowPasswordText(!showPasswordText)}/> */}
          </Form.Group>
        <Button className="btn btn-lg btn-primary btn-auth w-100 d-block mt-5 mb-4" size="lg" variant="primary" type="submit" style={{backgroundColor: '#29357c'}}>Log In</Button>
      </Form>
    </Modal>
  )
}
