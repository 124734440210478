import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import CourseAPI from '../../../api/Course'
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';


function UpdateCourseModal({
  showUpdateCourseModal,
  setShowUpdateCourseModal,
  courseId,
  courseName,
  link,
  setLink,
  price,
  setPrice,
  subjectId,
  courseDescription,
  setCourseName,
  setCourseDescription,
  getUserCourse,
  courseColor,
  setCourseColor,
  getAllCourse,
}) {

  const [validated, setValidated] = useState(false);

  const updateCourse = async (e) => {
    e.preventDefault()
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    if (courseName === '' || courseDescription === '') {
      toast.error('Please input all required fields', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } else {
      let data = {
        course_name: courseName,
        course_description: courseDescription,
        color: courseColor,
        subject_area_id: subjectId,
        link: link,
        price: price,
      }

      const response = await new CourseAPI().updateCourse(courseId, data)
      if (response.ok) {
        toast.success('Successfully updated course', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        closeModal()
        getAllCourse();
      } else {
        alert('Fields must be filled out')
      }
    }
  }

  const closeModal = () => {
    setShowUpdateCourseModal(false)
    setValidated(false)
    setCourseName('')
    setCourseDescription('')
    setCourseColor('')
  }

  return (
    <Modal
      size="lg"
      show={showUpdateCourseModal}
      onHide={() => closeModal()}
      aria-labelledby="example-modal-sizes-title-lg"
    >

      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Update Book
        </Modal.Title>
      </Modal.Header>

      <Form noValidate validated={validated} onSubmit={(e) => updateCourse(e)} >
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Book Name:</Form.Label>
            <Form.Control required defaultValue={courseName} onChange={(e) => setCourseName(e.target.value)} type="text" placeholder="Type Book Name here" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Book Description:</Form.Label>
            <Form.Control required defaultValue={courseDescription} onChange={(e) => setCourseDescription(e.target.value)} type="text" placeholder="Type Book Description here" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Link:</Form.Label>
            <Form.Control defaultValue={link} onChange={(e) => setLink(e.target.value)} type="text" placeholder="Type Book Link here" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Price:</Form.Label>
            <Form.Control defaultValue={price} onChange={(e) => setPrice(e.target.value)} type="text" placeholder="Type Book Price here" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' variant="primary" >
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>

  )

}

export default UpdateCourseModal