import '../../assets/index.css';
import React, { useEffect, useState } from "react";
import { Form, InputGroup, Button, Row, Col, Card, Dropdown } from 'react-bootstrap'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Link, useHistory } from 'react-router-dom'
import ontraqLogo from '../../assets/images/ontraq-logo.png'
import parentlineLogo from '../../assets/images/parentline-logo.png'
import Auth from '../../api/Auth';
import AddBook from '../Login/Login';
import searchIcon from '../../assets/images/searchIcon.png'
import CourseCard from '../course/components/CourseCard';

export default function Dashboard() {
  const [loading, setLoading] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [searchTeacherItem, setSearchTeacherItem] = useState('')

  const onSearch = (text) => {
    setSearchTeacherItem(text)
  }

  return (
    <div style={{padding: 40}}>
      <CourseCard />
    </div>
    );
}