import Base from './Base';

export default class Course extends Base {
  getAllCourse = async () => {
    return this.sendRequest({
      path: `/api/v1/course/all`,
      method: 'GET'
    });
  };

  publicCourse = async () => {
    return this.sendRequest({
      path: `/api/v1/course/public`,
      method: 'GET'
    })
  }

  enrollCourse = async (data) => {
    return this.sendRequest({
      path: `/api/v1/enrolled-course/create`,
      method: 'POST',
      data
    })
  }

  getEnrolledTeachers = async (id) => {
    return this.sendRequest({
      path: `/api/v1/enrolled-course/${id}/teachers`,
      method: 'GET'
    })
  }
  
  enrolledCourse = async () => {
    return this.sendRequest({
      path: `/api/v1/enrolled-course/all`,
      method: 'GET'
    })
  }

  createCourse = async (data) => {
    return this.sendRequest ({
      path: `/api/v1/course/create`,
      method: 'POST',
      data
    })
  }

  submitLesson = async (data) => {
    return this.sendRequest({
      path: `/api/v1/module/create`,
      method: 'POST',
      data
    })
  }

  getAllLessonModule = async (courseId) => {
    return this.sendRequest ({
      path: `/api/v1/course/${courseId}/module/all`,
      method: 'GET'
    })
  }

  updateLesson = async (moduleId, data) => {
    return this.sendRequest({
      path: `/api/v1/module/${moduleId}`,
      method: 'PUT',
      data
    });
  };

  getLessonModule = async (moduleId) => {
    return this.sendRequest({
      path: `/api/v1/module/${moduleId}`,
      method: 'GET'
    })
  }

  deleteLessonModule = async (moduleId) => {
    return this.sendRequest({
      path:`/api/v1/module/${moduleId}`,
      method: 'DELETE'
    })
  }

  deleteEnrolledParticipant = async (id) => {
    return this.sendRequest({
      path:`/api/v1/enrolled-course/${id}`,
      method: 'DELETE'
    })
  }

  acceptParticipant = async (id, data) => {
    return this.sendRequest ({
      path:`/api/v1/enrolled-course/${id}`,
      method: 'PUT',
      data
    })
  }

  deleteCourse = async (id) => {
    return this.sendRequest ({
      path:`/api/v1/course/${id}`,
      method: 'DELETE',
    })
  }

  updateCourse = async (courseId, data) => {
    return this.sendRequest ({
      path:`/api/v1/course/${courseId}`,
      method: 'PUT',
      data
    })
  }

  getCourse = async (courseId) => {
    return this.sendRequest ({
      path:`/api/v1/course/${courseId}`,
      method: 'GET'
    })
  }


  uploadVidCourseModule = async (data) => {
    return this.sendRequest({
      path:`/api/v1/course-video/upload`,
      method: 'POST',
      data
    })
  }

  getLessonVideo = async (course_id, module_id) => {
    return this.sendRequest({
      path:`/api/v1/course-video/${course_id}/module/${module_id}`,
      method: 'GET'
    })
  }

  getUserCourse = async (userId) => {
    return this.sendRequest({
      path:`/api/v1/course/all/user/${userId}`,
      method: 'GET'
    })
  }

  getFileItems = async (courseId) => {
    return this.sendRequest({
      path:`/api/v1/course-file/${courseId}/getallcoursefile`,
      method: 'GET'
    })
  }

  deleteFileItem = async (course_file_id) => {
    return this.sendRequest({
      path: `/api/v1/course-file/${course_file_id}`,
      method: 'DELETE'
    })
  }

  getCourseByCreator = async (user_account_id) => {
    return this.sendRequest({
      path: `/api/v1/course/all/user/${user_account_id}`,
      method: 'GET'
    })
  }

  getCourseForTeacher = async (teacherId) => {
    return this.sendRequest({
      path: `/api/v1/course/${teacherId}/unenrolledcourselist`,
      method: 'GET'
    })
  }

  assessment = async () => {
    return this.sendRequest({
      path: `/api/v1/assessment/all`,
      method: 'GET'
    });
  };

  submitAssessment = async (data) => {
    return this.sendRequest({
      path: `/api/v1/teacher-assessment/answer`,
      method: 'POST',
      data
    });
  };

  teacherStatus = async () => {
    return this.sendRequest ({
      path: `/api/v1/teacher-assessment/status`,
      method: 'PUT'
    })
  }

  getAllCoursePerTeacher = async (teacher_id) => {
    return this.sendRequest({
      path: `/api/v1/enrolled-course/${teacher_id}/enrolledcourse`,
      method: 'GET'
    })
  }

  getCourseExamView = async (courseExamId) => {
    return this.sendRequest({
      path: `/api/v1/exam/${courseExamId}/part/all/question/all`,
      method: 'GET'
    })
  }

  getCourseExamTitle = async (courseExamId) => {
    return this.sendRequest({
      path: `/api/v1/exam/course/${courseExamId}/module/all`,
      method: 'GET'
    })
  }

  submitExam = async (data, exam_id, teacher_id) => {
    return this.sendRequest({
      path: `/api/v1/exam/${exam_id}/teacher/${teacher_id}/answer`,
      method: 'POST',
      data
    });
  };

  getCourseExamPart = async (exam_id) => {
    return this.sendRequest({
      path: `/api/v1/exam/${exam_id}/part/all`,
      method: 'GET'
    })
  };

  getCourseExamScore = async (exam_id, teacher_id) => {
    return this.sendRequest({
      path: `/api/v1/exam/${exam_id}/teacher/${teacher_id}/answer/all`,
      method: 'GET'
    })
  };

  getCourseReports = async (course_id) => {
    return this.sendRequest ({
      path: `/api/v1/exam/course/${course_id}/report`,
      method: 'GET'
    })
  };

  examRetake = async (teacher_id, exam_id) => {
    return this.sendRequest ({
      path: `/api/v1/exam/participant/${teacher_id}/exam/${exam_id}/retake`,
      method: 'DELETE' 
    })
  };

  deleteCourseExam = async (exam_id) => {
    return this.sendRequest ({
      path: `/api/v1/exam/${exam_id}`,
      method: 'DELETE'
    })
  }

  deleteCourseVideo = async (course_video_id) => {
    return this.sendRequest ({
      path: `/api/v1/course-video/${course_video_id}`,
      method: 'DELETE'
    })
  }

  getTrainorsDetails = async () => {
    return this.sendRequest ({
      path: `/api/v1/admin/courses-enrolled/trainers`,
      method: 'GET'
    })
  }

  getParticipantCertificate = async (participant_id, course_id) => {
    return this.sendRequest ({
      path: `/api/v1/certificate/${participant_id}/course/${course_id}`,
      method: 'GET'
    })
  }
  getModuleContents = async (course_id, module_id) => {
    return this.sendRequest ({
      path: `/api/v1/module/modulecontent/course/${course_id}/module/${module_id}/modulecontents`,
      method: 'GET',
    })
  }

  getModuleContentById = async (module_content_id) => {
    return this.sendRequest ({
      path: `/api/v1/module/modulecontent/${module_content_id}`,
      method: 'GET'
    })
  }

  deleteModuleById = async (module_content_id) => {
    return this.sendRequest ({
      path: `/api/v1/module/modulecontent/${module_content_id}/delete`,
      method: 'DELETE'
    })
  }

  updateModuleById = async (module_content_id, data) => {
    return this.sendRequest ({
      path: `/api/v1/module/modulecontent/${module_content_id}`,
      method: 'PUT',
      data
    })
  }

  getCertificate = async (participant_id, course_id) => {
    return this.sendRequest ({
      path: `/api/v1/certificate/${participant_id}/course/${course_id}`,
      method: 'GET'
    })
  }

  profilePicture = async (id, data) => {
    return this.sendRequest ({
      path: `/api/v1/user/${id}/uploadpicture`,
      method: 'PUT',
      data
    })
  }

  userById = async (id) => {
    return this.sendRequest ({
      path: `/api/v1/user/${id}`,
      method: 'GET'
    })
  }

  getModule = async (module_id) => {
    return this.sendRequest ({
      path: `/api/v1/module/${module_id}`,
      method: 'GET'
    })
  }

  deleteCertificate = async (certificate_id) => {
    return this.sendRequest ({
      path: `/api/v1/certificate/${certificate_id}`,
      method: 'DELETE'
    })
  }

  createModuleExam = async (data) => {
    return this.sendRequest ({
      path: `/api/v1/exam/create`,
      method: 'POST',
      data
    })
  }

  getModuleExam = async (course_id, module_id) => {
    return this.sendRequest ({
      path: `/api/v1/exam/course/${course_id}/module/${module_id}`,
      method: 'GET'
    })
  }

  updateModuleExam = async (exam_id, data) => {
    return this.sendRequest ({
      path: `/api/v1/exam/${exam_id}`,
      method: 'PUT',
      data
    })
  }

  deleteModuleExam = async (exam_id) => {
    return this.sendRequest ({
      path: `/api/v1/exam/${exam_id}`,
      method: 'DELETE'
    })
  }

  getModuleExamById = async (exam_id) => {
    return this.sendRequest ({
      path: `/api/v1/exam/${exam_id}`,
      method: 'GET'
    })
  }

  addExamPart = async (exam_id, data) => {
    return this.sendRequest ({
      path: `/api/v1/exam/${exam_id}/part`,
      method: 'POST',
      data
    })
  }

  deleteExamPart = async (exam_part_id) => {
    return this.sendRequest ({
      path: `/api/v1/exam-part/${exam_part_id}`,
      method: 'DELETE'
    })
  }

  updateExamPart = async (exam_part_id, data) => {
    return this.sendRequest ({
      path: `/api/v1/exam-part/${exam_part_id}`,
      method: 'PUT',
      data
    })
  }

  getExamPart = async (exam_id) => {
    return this.sendRequest ({
      path: `/api/v1/exam/${exam_id}/part/all`,
      method: 'GET'
    })
  }

  addQuestionPerExamPart = async (exam_id, exam_part_id, data) => {
    return this.sendRequest ({
      path: `/api/v1/exam/${exam_id}/part/${exam_part_id}/question`,
      method: 'POST',
      data
    })
  }

  getExamPartQuestions = async (exam_id, exam_part_id) => {
    return this.sendRequest ({
      path: `/api/v1/exam/${exam_id}/part/${exam_part_id}/question/all`,
      method: 'GET'
    })
  }

}
